body {
  background-color: #222;
  font-size: 16px;
}

.modal-x-large {
  min-width: 96%;
}

.modal-large {
  min-width: 50%;
}

.modal-medium {
  max-width: 35%;
}

.spinner-border-small {
  width: 1rem;
  height: 1rem;
}

.help-container {
  font-style:italic;
  padding: 15px;
  margin-bottom: 10px;
}

.password-view-toggle-container{
  background-color: #FFF;
  width: 32px;
  padding: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.password-view-toggle-icon{
  color: #222;
}

.DayPickerInput {
  color: black;
}

.DayPickerInput input {
  width: 100%;
}

/** General Rows */

.row {
  margin-bottom: 10px;
}

.row-header {
  text-decoration: underline;
}

/** Tools **/
.tool-row {
  margin-bottom: 10px;
}

.tool-title {
  font-size: 24px;
}

.tool-instructions {
  border-bottom: thin solid gray;
  padding-bottom: 20px;
}

.tool-item-row {
  border-bottom: thin solid gray;
  padding-top: 10px;
  padding-bottom: 20px;
}

.actionable {
  cursor: pointer;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: .9375rem;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}